import { Box, SimpleGrid, Text, HStack, Image } from '@chakra-ui/react';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';

import Title from '@/components/head/title';
import ApolloClientManager from '@/libs/apolloClient/apolloClientManager';
import { liffSsrStateRedirect } from '@/libs/liffSupport';
import { LayoutNextPage } from '@/types';
import {
  GetIndexPageDocument,
  GetIndexPageQuery,
  GetIndexPageQueryVariables,
} from '@/userTool/graphql/pages/index.generated';
import UserPageLayout from '@/userTool/layouts/userPageLayout';

export interface ServerProps {
  rvPage?: GetIndexPageQuery; // rvPage is optional because liff.state parameter can cause redirection (see getServerSideProps)
}

const title = '予約ページ一覧 ';

const TenantTop: LayoutNextPage<ServerProps> = function TenantTop(props: ServerProps) {
  const { rvPage } = props;
  const router = useRouter();

  const handleNextLink = async (rvPageCode: string) => {
    await router.push(`/${rvPageCode}`);
  };

  return (
    <>
      <Title title={title} />
      <Box>
        {rvPage && rvPage.reservationPages?.data && (
          <SimpleGrid columns={1}>
            {rvPage.reservationPages.data.map((reservationPage) => {
              return (
                <Box
                  key={reservationPage.rv_page_code}
                  marginX="4"
                  mb="4"
                  bg="white"
                  boxShadow="base"
                  rounded="md"
                  onClick={async () => {
                    await handleNextLink(String(reservationPage?.rv_page_code));
                  }}
                >
                  {reservationPage.rv_page_code && (
                    <HStack padding="4" spacing="4" alignItems="flex-start">
                      {reservationPage.coverImage?.url && (
                        <Image
                          src={reservationPage.coverImage?.url}
                          boxSize="20"
                          objectFit="cover"
                          alt={reservationPage.name || ''}
                        />
                      )}
                      <Text fontWeight="bold">{reservationPage.name}</Text>
                    </HStack>
                  )}
                </Box>
              );
            })}
          </SimpleGrid>
        )}
      </Box>
    </>
  );
};

TenantTop.getLayout = (page: ReactElement<ServerProps>) => {
  const { rvPage } = page.props;
  return (
    <UserPageLayout canShowProfile pageNameProp={rvPage ? rvPage.currentTenant?.name || '' : ''}>
      {page}
    </UserPageLayout>
  );
};
export const getServerSideProps: GetServerSideProps<ServerProps> = async (ctx) => {
  // liff ssr redirect
  const liffRedirect = liffSsrStateRedirect(ctx);
  if (liffRedirect) {
    return {
      redirect: liffRedirect,
    };
  }

  const client = ApolloClientManager.getFromSSR('api', ctx);
  const rvPage = await client.query<GetIndexPageQuery, GetIndexPageQueryVariables>({
    query: GetIndexPageDocument,
    variables: {
      first: 1000,
    },
  });

  return {
    props: {
      rvPage: rvPage.data,
    },
  };
};

export default TenantTop;
