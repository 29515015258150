import { Box, Flex, Button, Text, BoxProps, Avatar, Link } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Title from '@/components/head/title';
import LiffContext from '@/userTool/contexts/liffContext';
import UserToolTenantContext from '@/userTool/contexts/UserToolTenantContext';

interface UserPageLayoutPropTypes {
  canShowProfile?: boolean;
  canShowBackBtn?: boolean;
  pageNameProp: string;
  contentProps?: BoxProps;
  contentPadding?: string | number;
  backLink?: string;
}

const UserPageLayout: React.FC<UserPageLayoutPropTypes> = function UserPageLayout(prop) {
  const { t } = useTranslation();
  const { isLoggedIn, displayName, pictureUrl } = useContext(LiffContext);
  const {
    children,
    canShowProfile,
    canShowBackBtn,
    pageNameProp,
    contentProps,
    contentPadding,
    backLink,
  } = prop;
  const pageName = pageNameProp ?? '{reservation page name}';
  const { themeColor } = useContext(UserToolTenantContext);
  const router = useRouter();
  const pageNameMargin =
    // eslint-disable-next-line no-nested-ternary
    !canShowBackBtn && isLoggedIn
      ? 20
      : // eslint-disable-next-line no-nested-ternary
      !isLoggedIn && !canShowBackBtn
      ? 0
      : canShowBackBtn && !isLoggedIn
      ? -20
      : 0;
  return (
    <>
      <Title title={pageNameProp} />
      <Flex minHeight="100vh" flexDirection="column" bgColor="gray.50">
        <Flex
          borderBottomColor="gray.200"
          borderBottomWidth={1}
          justifyContent={`${canShowBackBtn || canShowProfile ? 'space-between' : 'center'}`}
          alignItems="center"
          paddingX="4"
          paddingY="3"
          minHeight="16"
          maxHeight="16"
          width="100%"
          flexGrow="0"
          position="sticky"
          bgColor={`${themeColor}.500`}
          top="0"
          zIndex="calc(var(--chakra-zIndices-modal) - 1)"
        >
          {canShowBackBtn && (
            <Button
              colorScheme={`${themeColor}.500`}
              variant="outline"
              color="white"
              maxHeight="8"
              onClick={() => (backLink ? router.push(backLink) : router.back())}
            >
              {t('userTool:labels.button.back')}
            </Button>
          )}
          <Flex flexGrow={1} justifyContent="center">
            <Text
              alignItems="center"
              color="white"
              fontSize="16px"
              fontWeight="bold"
              style={{ marginLeft: pageNameMargin }}
            >
              {pageName}
            </Text>
          </Flex>
          <Flex>
            {isLoggedIn && (
              <Link href="/mypage/reservation">
                <Avatar name={displayName} src={pictureUrl} />
              </Link>
            )}
          </Flex>
        </Flex>
        <Box flexDirection="column" justifyContent="center">
          <Box paddingY={contentPadding}>
            <Box {...contentProps} marginX="auto">
              <main>{children}</main>
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

UserPageLayout.defaultProps = {
  canShowProfile: false,
  canShowBackBtn: false,
  contentProps: {},
  contentPadding: 6,
  backLink: '',
};
export default UserPageLayout;
